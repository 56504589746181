* {
  transition: height 0.8s ease;
  -webkit-transition: height 0.8s ease;
  -moz-transition: height 0.8s ease;
  -o-transition: height 0.8s ease;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #5fc0c8;
  color: #ffffff;
  font-size: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  vertical-align: middle;
  border: none;
  box-shadow: none;
}

button:focus {
  outline: none;
}
/* 
.wave {
  position: fixed;
  bottom: 0;
  background-image: url("assets/wave.svg");
  height: 40vh;
  width: 100vw;
  background-size: cover;
  z-index: -10;
} */

#quizContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  height: 100vh;
  width: 100vw;
  min-height: -webkit-fill-available;
  overflow: hidden;
  scroll-behavior: smooth;
}

/* #results {
  display: block;
} */

.questionContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  padding: 20px 10px;
  flex: none;
  position: relative;
  margin: 0 200vw;
  overflow-y: scroll;
}

.questionContainer:first-of-type {
  margin-left: 0;
}

.questionContainer:last-of-type {
  margin-right: 0;
}

.innerContainer {
  margin: auto;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  min-width: 300px;
}

.question {
  display: flex;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  max-width: 480px;
  margin: 0px 0 20px 0;
}

.description {
  font-weight: 700;
  font-size: 20px;
  color: #cfffff;
  text-align: center;
  margin: -10px 0 20px 0;
  max-width: 480px;
}

.icon {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0);
  height: 40px;
  width: 40px;
  background-size: contain;
  box-shadow: 1px 2px 6px 0px rgba(40, 90, 100, 0.4);
  border-radius: 50%;
  box-sizing: border-box;
  padding: 0;
  background-repeat: no-repeat;
}

.plus {
  background-image: url("./assets/plus.svg");
}

.minus {
  background-image: url("./assets/minus.svg");
}

.back {
  background-image: url("./assets/back.svg");
  height: 40px;
  width: 40px;
}

.restart {
  background-image: url("./assets/restart.svg");
  height: 40px;
  width: 40px;
}

.pickerValue {
  vertical-align: middle;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin: 20px;
}

.subquestion {
  font-size: 16px;
  margin: 0 0 5px 0;
  width: 300px;
  box-sizing: border-box;
}

.subquestionButton {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 5px;
  border-radius: 20px;
  margin: 0 0 20px 0;
  width: 300px;
  box-shadow: 1px 2px 6px 0px rgba(40, 90, 100, 0.4);
}

.numberPicker .pickerValue {
  font-size: 48px;
}

.numberPickerMini,
.timeFramePickerMini {
  display: flex;
  align-items: center;
}

.numberPickerMini .icon,
.timeFramePickerMini .icon {
  height: 26px;
  width: 26px;
  box-shadow: none;
  border-radius: 50%;
}

.numberPickerMini .plus,
.timeFramePickerMini .plus {
  background-image: url(assets/plus_dark.svg);
}

.numberPickerMini .minus,
.timeFramePickerMini .minus {
  background-image: url(assets/minus_dark.svg);
}

.numberPickerMini .pickerValue {
  font-size: 24px;
  color: #5a5a5a;
  margin: 0 10px;
}

.timeFramePickerMini .pickerValue {
  font-size: 20px;
  color: #5a5a5a;
  margin: 0 10px;
}

.perMini {
  font-weight: bold;
  font-size: 10px;
  color: #666d6e;
  text-align: center;
  line-height: 10px;
  width: 30px;
  text-transform: uppercase;
  display: inline-block;
  margin: 0 15px;
}

.per {
  font-weight: bold;
  font-size: 16px;
  color: #cfffff;
  text-align: center;
  text-transform: uppercase;
}

.nav {
  /* position: absolute;
  top: 540px; */
  display: flex;
  width: 300px;
  justify-content: center;
  margin-top: 40px;
}

.next {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  max-width: 235px;
  height: 40px;
  background: #b7f7ff;
  border-radius: 25px;
  font-weight: bold;
  font-size: 20px;
  color: #3b585a;
  margin-left: 20px;
  text-align: center;
  box-shadow: 1px 2px 6px 0px rgba(40, 90, 100, 0.4);
}

button:disabled {
  background: #8bdbe3;
  color: #518185;
}

.radioContainer {
  display: flex;
}

.radioContainer input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0;
}

.radioButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  vertical-align: middle;
  font-weight: 500;
  font-size: 20px;
  color: #3b585a;
  background: #ffffff;
  border-radius: 450px;
  margin: 5px 0;
  box-shadow: 1px 2px 6px 0px rgba(40, 90, 100, 0.4);
}

.radioContainer:hover .radioButton {
  cursor: pointer;
}

.radioContainer input:checked ~ .radioButton {
  background: #92e1e9;
}

.resultTitle {
  font-weight: bold;
  font-size: 30px;
  color: #CFF9FF;
  text-align: center;
  margin: 0px auto 10px;
}

.result {
  margin: 10px auto 10px;
  text-align: left;
}

#avgResults{
  margin-bottom: 100px;
}

.resultLabel {
  font-weight: 800;
  font-size: 16px;
  color: #CFF9FF;
  text-transform: uppercase;
}

.resultValue {
  font-weight: bold;
  font-size: 60px;
  color: #FFFFFF;
}

.resultUnits {
  font-weight: 800;
  font-size: 14px;
  color: #CFF9FF;
  text-transform: uppercase;
  vertical-align: middle;
  padding-left: 10px;
}

.pieTitle {
  font-weight: 800;
  font-size: 20px;
  color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
  margin: 20px auto 0px auto;
}


.down {
  font-weight: bold;
  font-size: 24px;
  color: #CFF9FF;
  text-align: center;
  margin: 80px auto 40px;
}

.downIcon {
  background-image: url('assets/down.svg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 50px;
  margin: 10px auto;
}

.progressBack {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 30px;
  /* background-image: linear-gradient(180deg, rgba(24,114,134,0.0) 0%, #015871 100%); */
  background: #40ABBC;
}

.progressBar {
  position: absolute;
  bottom: 10px;
  left: 20px;
  height: 10px;
  width: calc(100% - 40px);
  background: #92e1e9;
  border-radius: 10px;
  /* border: 1px solid #33919f; */
}

#progress {
  height: 10px;
  width: 0%;
  background: #ffffff;
  border-radius: 10px;
}

.removeMe {
  position: fixed;
  top: 10px;
  left: 10px;
  margin: 0;
}

.tableTitle {
  margin-left: 10px;
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 20px;
  color: #FFFFFF;
  text-transform: uppercase;
}

table, td, th{
  /* border: 1px solid #40ABBC; */
  box-sizing: border-box;
  border-spacing: 0px;
}

table {
  /* border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px; */
  color: #333;
  width: 100vw;
}

tr {
  background: #ffffff;
}

tr:nth-of-type(2n + 1) {
  background: #f4f4f4;
}

th {
  color: #ffffff;
  background: #40ABBC;
}

td, th {
  padding: 10px;
  font-size: 16px;
  text-align: center;
}

/* th:first-child {
  -moz-border-radius: 10px 0 0 0;
  -webkit-border-radius: 10px 0 0 0;
  border-radius: 10px 0 0 0;
}
th:last-child {
  -moz-border-radius: 0 10px 0 0;
  -webkit-border-radius: 0 10px 0 0;
  border-radius: 0 10px 0 0;
}
th:only-child{
  -moz-border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
tr:last-child td:first-child {
  -moz-border-radius: 0 0 0 10px;
  -webkit-border-radius: 0 0 0 10px;
  border-radius: 0 0 0 10px;
}
tr:last-child td:last-child {
  -moz-border-radius: 0 0 10px 0;
  -webkit-border-radius: 0 0 10px 0;
  border-radius: 0 0 10px 0;
}  */

td:first-child, th:first-child {
  text-align: left;
}