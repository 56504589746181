.ocean { 
  height: 0px;
  width:100vw;
  position:fixed;
  bottom: 0px;
  left:0;
  /* background-image: linear-gradient(180deg, rgba(24,114,134,0.80) 0%, #015871 80%); */
  background: #40ABBC;
  z-index: -10;
}


.wave {
  background: url('assets/wave_solid.svg') repeat-x;
  position: absolute;
  top: -60px;
  width: 6400px;
  height: 60px;
  animation: wave 12s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}