.middleLabel {
    font-size: 18px;
}

.pie-row .pie-wrap > div {
    background: red;
    margin: 0 auto;
}

svg {
    overflow: visible;
}

text {
    font-weight: 500;
    position: relative;
}

.value {
    font-size: 20px;
}

.units {
    text-transform: uppercase;
    font-size: 10px;
}